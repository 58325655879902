import { Link } from 'gatsby';
import * as React from 'react';

interface IProps {
  onMouseOver(): void;
  gameImage: JSX.Element;
  gamePath?: string;
  gameName?: string;
  new?: boolean;
}

export const GameSwitcherItem: React.FC<IProps> = (props) => {
  return (
    <Link to={props.gamePath ? props.gamePath : '/'}>
      <div className="game-category" onMouseOver={() => props.onMouseOver()}>
        <div className="game-box">
          <div className="game-image">{props.gameImage}</div>
        </div>
        <div className="game-name">
          <h2>
            {props.gameName}{' '}
            {props.new && (
              <>
                <span className="tag new">NEW!</span>
              </>
            )}
          </h2>
        </div>
      </div>
    </Link>
  );
};
