import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/Store';

import './game-background.scss';

export const GameBackground: React.FC = () => {
  const model = useSelector((state: RootState) => ({
    main: state.main
  }));

  return (
    <div className={`background-switcher ${model.main.gameSwitcherHover}`}>
      <div className="background-inner cs"></div>
      <div className="background-inner ag"></div>
      <div className="background-inner nikke"></div>
      <div className="background-inner lc"></div>
      <div className="background-inner es"></div>
      <div className="background-inner hsr"></div>
      <div className="background-inner bcm"></div>
      <div className="background-inner tog"></div>
      <div className="background-inner reverse"></div>
      <div className="background-inner zzz"></div>
      <div className="background-inner afk"></div>
      <div className="background-inner endfield"></div>
      <div className="background-inner exilium"></div>
      <div className="background-inner ww"></div>
      <div className="background-inner solo"></div>
    </div>
  );
};
